<template>
  <div class="drag-and-drop">
    <div
      class="drag-and-drop__image-container"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      v-loading="convertingImage"
      :key="key"
    >
      <button
        v-if="image || $attrs.imageSrc"
        @click="deleteImage"
        title="Delete image"
        class="drag-and-drop__delete-button"
      >
        <i class="ion ion-trash-outline"></i>
      </button>
      <div class="drag-and-drop__content" v-if="!isDragEnter && !image && !$attrs.imageSrc">
        <i class="ion ion-image-outline drag-and-drop__content__icon"></i>
        <span class="drag-and-drop__content__text">
          {{ $t("settings.dropImage") }} or&nbsp;
          <p>{{ $t("settings.clickUpload") }}</p>
        </span>
      </div>
      <div class="drag-and-drop__content" v-if="isDragEnter">
        <i class="ion ion-download-outline drag-and-drop__content__icon"></i>
        <span class="drag-and-drop__content__text"> {{ $t("settings.dropIt") }}!</span>
      </div>
      <div
        class="drag-and-drop__content drag-and-drop__content--selected"
        v-if="image || $attrs.imageSrc"
      >
        <img :src="imageBase64 || $attrs.imageSrc" class="drag-and-drop__image" />
        <!-- <span class="drag-and-drop__content__text-selected">
          {{ image.name }}
        </span>
        <i
          @click="deleteImage()"
          class="ion ion-close-outline drag-and-drop__content__icon-selected"
        ></i> -->
      </div>
      <input
        class="drag-and-drop__files-input"
        @change.prevent="dropAttachment($event)"
        type="file"
        accept=".png, .jpg, .jpeg"
        v-if="!image && !$attrs.imageSrc"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DragAndDrop",
  prop: {
    imageSrc: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      image: null,
      isDragEnter: false,
      imageBase64: "",
      convertingImage: false,
      key: 0,
    };
  },
  methods: {
    async dropAttachment(event) {
      /* eslint-disable prefer-destructuring */
      this.image = event.target.files[0];
      this.convertingImage = true;
      this.imageBase64 = await this.blobToBase64(this.image);
      this.convertingImage = false;
      this.$emit("input", this.image);
      this.isDragEnter = false;
    },
    dragEnter() {
      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    deleteImage() {
      this.image = null;
      this.$attrs.imageSrc = null;
      this.$emit("input", null);
      this.key += 1;
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-and-drop {
  font-family: $font-family-portal;
  &__image-container {
    position: relative;
    max-width: 400px;
    min-height: 150px;
    border: 1px dashed $color-border-container;
    background-color: white;
    border-width: 2px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__delete-button {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 9%;
    background-color: $color-remove-inactive-button;
    border-radius: 50px;
    border: none;
    color: $color-light;
    z-index: 1;
    // box-shadow: 2px 2px 4px #000;
    @media (max-width: 440px) {
      width: 10%;
      right: -5px;
  }
    & i {
      font-size: 23px;
    }
  }
  &__files-input {
    width: 100%;
    min-height: 150px;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    &--selected {
      position: relative;
      display: flex;
      flex-direction: row;
      border: none;
      padding: 1%;
    }
    &__text {
      display: flex;
      flex-direction: row;
      color: $color-primary-company;
      p {
        color: $color-border-container;
      }
    }
    &__text-selected {
      display: flex;
      flex-direction: row;
      color: $color-primary-company;
    }
    &__icon {
      font-size: 50px;
      color: $color-border-container;
    }
    &__icon-selected {
      z-index: 5;
      font-size: 25px;
      color: $color-error;
      font-weight: bold;
    }
  }
}
</style>
