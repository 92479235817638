<template>
  <div class="logos-customization">
    <div class="logos-customization__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="logos-customization-container">
      <span class="error-message" v-if="errorImageRequired">The logo image is required*</span>
      <DragAndDropTab :logos="logosCopy" :documentsOptions="accountSettings.documents_logo_options" v-model="appLogos" />
      <button v-loading="updatingLogos" @click="saveLogos()" :disabled="errorImageRequired" class="save-button">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import DragAndDropTab from "../Branding/ThemeMaker/components/DragAndDropTab.vue";

export default {
  name: "Logos",
  components: {
    DragAndDropTab,
  },
  props: {
    documentsOptions: Object,
  },
  data() {
    return {
      appLogos: {
        documentsLogo: "",
      },
      logosCopy: {},
      accountSettings: {},
      updatingLogos: false,
      errorImageRequired: false
    };
  },
  watch: {
    appLogos() {
      if (this.appLogos.documentsLogo) {
        this.errorImageRequired = false;
        return;
      }
      this.errorImageRequired = true;
    }
  },
  created() {
    this.accountSettings = JSON.parse(localStorage.getItem("user-settings"));
    const documentsLogo = this.accountSettings.documents_logo;
    this.logosCopy = { documentsLogo };
    this.appLogos = { ...this.logosCopy };
  },
  methods: {
    async appLogosHandler(id) {
      const promises = [];
      const response = {
        documents_logo: this.appLogos.documentsLogo,
      };
      if (this.appLogos.documentsLogo !== this.logosCopy.documentsLogo) {
        promises.push(this.updateDocumentsLogo(id));
      }
      await Promise.all(promises).then((data) => {
        // This loop is to correctly assign the logos to the settings object.
        data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            response[key] = item[key];
          });
        });
      });
      return response;
    },
    async saveLogos() {
      if (this.errorImageRequired) {
        return;
      }
      if (this.$options.filters.checkPermission("patch:account-settings")) {
        this.updatingLogos = true;
        const id = this.$store.getters["login/getTokenInfo"].account_id;
        const settingsSchema = JSON.parse(localStorage.getItem("user-settings"));
        settingsSchema.documents_logo_options = this.appLogos.documents_logo_options;
        const logosResponse = await this.appLogosHandler(id);
        Object.assign(settingsSchema, logosResponse);
        await this.$store.dispatch("account/updateAccountSettings", {
          id: { account_id: id },
          settings: settingsSchema,
        });
        this.$message({
          type: "success",
          message: "Theme updated successfully. Refresh the page to see all changes.",
          duration: 4000,
        });
        localStorage.setItem("user-settings", JSON.stringify(settingsSchema));
        this.updatingLogos = false;
      }
    },
    updateDocumentsLogo(id) {
      const imageFormData = new FormData();
      imageFormData.append("file", this.appLogos.documentsLogo);
      return this.$store.dispatch("account/updateDocumentsLogo", {
        id,
        formData: imageFormData,
      });
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logos-customization {
  display: flex;
  flex-direction: column;
  font-family: $font-family-portal;
  padding: 2%;

  &-container {
    position: relative;
  }

  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 2% 0;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;

    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}

.error-message {
  color: red;
  font-size: 14px;
  position: absolute;
  left: 0px;
  top: 28px;
}

.save-button {
  margin: 25px auto 0px auto;
  display: flex;
  justify-content: space-around;
  @include secondary-button;
  background-color: $color-save-button;
  color: $color-white;
  border: none;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  width: 20%;
  text-align: center;
  padding: 7px;
}
</style>
